@import '../../styles/base.scss';

.navbar {
    display: flex;
    flex-direction: row;
    padding: 2rem $desktop-padding;
    justify-content: space-between;
    align-items: center;
    font-family: $heading-font;
    z-index: 999999;
    position: relative;

    @media only screen and (max-width: $mobile) {
        padding: 1.5rem $mobile-padding;
    }
    ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        align-items: center;

        @media only screen and (max-width: $mobile) {
            display: none;
        }
        li {
            margin-left: 4rem;

            @media only screen and (max-width: $desktop) {
                margin-left: 1rem;
            }

            .active {
                font-family: 'Brice Bold';
            }
            
            a {
                color: white;
                text-decoration: none;
                font-size: 1.25rem;
                
                @media only screen and (max-width: $desktop) {
                    font-size: 1rem;
                }
            }
            .button {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: $accent-color-1;
                color: black;
                padding: 0.7rem 1rem;
                transition: 0.25s;

                @media only screen and (max-width: $desktop) {
                    padding: 0.3rem 0.6rem;
                }
                p {
                    font-family: $title-font;
                    font-size: 1.25rem;
                    line-height: 1em;
                    height: 0.8em;
                    margin-right: 0.4rem;

                    @media only screen and (max-width: $desktop) {
                        font-size: 1rem;
                    }
                }
                img {
                    object-fit: contain;
                    height: 1.1rem;
                }

                &:hover, &:focus-visible {
                    transform: scale(0.9);
                }

                &:active {
                    background-color: #008437;
                }
            }
            
        }
    }
    .logo {
        object-fit: contain;
        max-width: 8rem;
        width: 10vw;
        min-width: 5rem;
    }
    .hamburger {
        display: none;

        @media only screen and (max-width: $mobile) {
            display: block;
            cursor: pointer;
            object-fit: contain;
            width: 2rem;
        }
    }
    .background {
        display: none;

        @media only screen and (max-width: $mobile) {
            display: flex;
            position: absolute;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100vw;
            background-color: $background-color;
            flex-direction: column;
            z-index: 100000000;

            li {
                list-style: none;
            }

            .top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 1.5rem $mobile-padding;
                border-bottom: 1px solid white;

                .mobile-logo {
                    object-fit: contain;
                    width: 5rem;
                }
                .mobile-hamburger {
                    cursor: pointer;
                    object-fit: contain;
                    width: 2rem;
                }
            }
            .middle {
                border-bottom: 1px solid white;
                padding: 1.5rem $mobile-padding;
                text-decoration: none;
                font-size: 1.5rem;
                
                text-decoration: none;
                font-family: $heading-font;

                a {
                    text-decoration:none;
                    color: white;

                }

                .active {
                    font-family: 'Brice Bold';
                }
            }
            .bottom {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-top: 1.5rem;

                .button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: $accent-color-1;
                    color: black;
                    padding: 1.25rem 1.25rem;
                    text-decoration: none;

                    &:active {
                        background-color: #008437;
                    }

                    p {
                        font-family: $title-font;
                        font-size: 1.5rem;
                        line-height: 1em;
                        height: 0.8em;
                        margin-right: 0.4rem;
                    }
                    img {
                        object-fit: contain;
                        height: 1.1rem;
                    }
                }
            }
        }
    }
}