@import '../../styles/base.scss';
.experience {
    .mobile-cont {

        h2 {
            margin-bottom: 2.5rem;
        }

        h4 {
            font-size: 1.3rem;
            font-family: $heading-font;
            font-weight: 400;
            line-height: 1.25rem;
        }

        h5 {
            color: white;
        }

        a {
            font-size: 1.15rem;
            font-family: $heading-font;
            color: black;
        }
        .mobile-experience {

            img {
                max-width: 100%;
                align-self: flex-start;
            }

            .symbol {
                position: absolute;
                object-fit: contain;
                width: 20vw;
            }
            .symbol.symbol1 {
                width: 22vw;
                transform: translate(-1.8rem, 4.8rem);
            }

            .symbol.symbol2 {
                width: 24vw;
                transform: translate(3.5rem, -2rem);
            }
            .symbol.symbol3 {
                transform: translate(15rem, -2rem);
            }
            .symbol.symbol4 {
                width: 20vw;
                transform: translate(-2.4rem, -2.8rem);
            }
            .symbol.symbol5 {
                width: 20vw;
                transform: translate(17.6rem, -6.6rem);
            }
            .symbol.symbol6 {
                width: 18vw;
                transform: translate(-2rem, -1.6rem);
            }
            .symbol.symbol7 {
                transform: translate(16rem, -3.6rem);
            }

            

          
            display: flex;
            flex-direction: column;
            padding: 8vw;

            > .section-one {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                background-color: $accent-color-1;
                color: black;
                height: 5.6rem;
                padding: 1.5rem 1.5rem 1.5rem 3rem;


                > .header-text {
                    text-align: right;
                    margin-bottom: 0.4rem;
                    text-transform: uppercase;

                }
            }

            .quote {
                display: flex;
                flex-direction: column;
                justify-content: start;
                padding: 2rem 0rem 2rem 0rem;

                p {
                    font-size: 0.9rem;
                    font-family: $p-font;
                    padding-bottom: 0.6rem;
                }
                h5 {
                    font-size: 1.1rem;
                    font-family: $heading-font;
                    line-height: 1.3;
                    font-weight: 400;
                    color: white;
                    margin-bottom: 0px;
                }

            }

            .quote.three {
                padding: 2rem 0rem 0rem 0rem;
            }
        }
        .pinkbar {
            height: 8vw;
            width: 100vw;
            background-color: #FF4081;

        }
        > .learn-more-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 3rem 4.4rem 3rem 4.4rem;
            text-align: center;

            h4 {
                font-size: 1.25rem;
            }

            > .link {
                text-decoration: none;
                > button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background-color: $accent-color-1;
                    padding: 0.5rem 0.8rem;
                    margin-top: 2rem;
                    transition: 0.25s;
                    cursor: pointer;
                    border: none;
                    width: 100%;
                    height: 3.25rem;
                    color: black;

                    &:hover {
                        transform: scale(0.9);
                    }

                    p {
                        transform: translateY(0.1em);
                        -webkit-transform: translateY(0.1em);
                        font-size: 1.4rem;
                        font-family: $title-font;

                    }
                    img {
                        width: 1.75rem;
                        margin-left: 1rem;
                    }

                    @media only screen and (max-width: $mobile) {
                        p {
                            font-size: 2rem;
                        }
                    }
                
                    @media only screen and (max-width: 460px) {
                        p {
                            font-size: calc(5.5vw);
                        }
                    }
                
                    @media only screen and (max-width: 390px) {
                        p {
                            font-size: calc(4.5vw);
                        }
                    }
                    @media only screen and (max-width: 340px) {
                        p {
                            font-size: calc(4vw);
                        }
                    }
                }
                > button:active {
                    background-color: #008437;
                }
            }
                
        }

    }
    
    .desktop-experience {
        display: none;
        padding: 4rem 8vw; 

        h2 {
            font-size: 4rem;
            margin-bottom: 4.4rem; 
            @media only screen and (max-width: $desktop) {
                font-size: 2.4rem;
            }
        }

        h4 {
            font-size: 3rem;
            font-family: $heading-font;
            margin-bottom: 1rem;
            font-weight: 400;
            line-height: 2.75rem;

            @media only screen and (max-width: $desktop) {
                font-size: 1.5rem;
            }
            @media only screen and (max-width: $mobile) {
                font-size: 1.25rem;
            }
        }
        h5 {
            font-size: 1.5rem;
            font-family: $heading-font;
            margin-bottom: 1rem;
            line-height: 1.3;
            font-weight: 400;
            color: white;

            @media only screen and (max-width: $desktop) {
                font-size: 1.2rem;
            }
        }
        p {
            font-size: 1rem;
            font-family: $p-font;

            @media only screen and (max-width: $desktop) {
                font-size: 0.9rem;
            }
        }
        a {
            font-size: 1.25rem;
            font-family: $heading-font;
            color: black;

            @media only screen and (max-width: $desktop) {
                font-size: 1rem;
            }
        }
        .symbol {
            position: absolute;
            object-fit: contain;
            width: 12vw;
        }

        > .apprentice-container {
            display: flex;
            flex-direction: column;

            > .section-1 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                position: relative;

                > .section-1-left {
                    display: flex;
                    flex-direction: column;

                    > #top {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        padding: 2rem;
                        padding-left: 18vw;
                        background-color: $accent-color-1;
                        color: black;
                        text-align: right;
                        height: 10vw;
                        padding: clamp(0rem, 2.4vw, 3rem);
                        > .section-1-symbol4 {
                            left: -3vw;
                            top: -4rem;
                            width: 18vw;
                            transition: 0.5s;
                        }
                        &:hover {
                            .section-1-symbol4 {
                                transform: rotate(15deg) translate(-1vw, -1.5vw);
                                transform-origin: center;
                            }
                        }

                        > h4 {
                            text-transform: uppercase;
                            width: 27vw;
                            letter-spacing: -0.05rem;
                            line-height: clamp(1rem, 2.4vw, 3rem);
                            font-size: clamp(0.5rem, 2.4vw, 3rem);
                            margin-bottom: 0vw;
                            height: 6vw;
                            white-space: normal;
                            padding-bottom: clamp(0.8rem, 1.6vw, 1.6rem);   
                            

                        }
                        > a {
                            font-size: clamp(0.4rem, 1.8vw, 2.2rem);
                        }

                        @media only screen and (max-width: $desktop) {
                            padding: 1.5rem;
                        }
                    }
                    > #bottom {
                        display: flex;
                        flex-direction: column;
                        padding: 2rem 4rem 2rem 4rem;
                        justify-content: space-between;
                        

                        @media only screen and (max-width: $desktop) {
                            padding: 1.5rem;
                        }

                        > p {
                            font-size: clamp(0.6rem, 1.2vw, 1rem);
                        }

                        > .quote-name {
                            margin: 0.5rem 0;
                            font-size: clamp(0.6rem, 2vw, 1.5rem);
                        }
                    }
                }
                > img {
                    width: 28vw;

                    @media only screen and (max-width: $desktop) {
                        width: 16rem;
                    }
                    @media only screen and (max-width: $mobile) {
                        font-size: 1.25rem;
                    }
                }

                @media only screen and (max-width: $mobile) {
                    flex-direction: column;
                }
            }
            > .section-2 {
                height: 20%;
                position: relative;
                padding-bottom: 0px;
                
                > .section-2-symbol3 {
                    right: -4vw;
                    top: -8rem;
                }
                > .section-2-photo {
                    width: 100%;
                    height: 100%;   
                    margin-bottom: 0px;
                    object-fit: contain;
                }
            }
            > .section-3 {
                display: flex;
                flex-direction: row;
                position: relative;
                margin-top: 0px;
                padding-top: 0px;
                > .section-3-symbol6 {
                    left: -4vw;
                    top: -8rem;
                }
                > .section-3-symbol7 {
                    width: 14vw;
                    right: -4vw;
                    top: -8rem;
                }
                > .section-3-left {
                    width: 42vw;
                    height: 100%;

                }
                > .section-3-right {
                    display: flex;
                    flex-direction: column;

                    /* > #top {
                        display: flex;
                        flex-direction: column;
                        padding: 2rem;
                        background-color: $accent-color-1;
                        color: black;
                        text-align: right;
                    } */
                    > #top {
                        width: 42vw;
                    }
                    > #bottom {
                        width: 40vw;
                        display: flex;
                        flex-direction: column;
                        padding: 1rem 0.5rem 0 1rem;

                        > p {
                            padding-top: clamp(0.6rem, 3vw, 1.4rem);
                            padding-left: 1rem;
                            padding-right: 1rem;
                            font-size: clamp(0.6rem, 1.2vw, 1rem);
                            margin-bottom: 0.4rem;
                            @media only screen and (max-width: $desktop) {
                                padding-top: 0.6rem;
                            }
                        }

                        > .quote-name {
                            font-size: clamp(0.6rem, 2vw, 1.5rem);
                            padding-left: 1rem;
                        }
                    }
                }
            }
            > .section-4 {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                position: relative;

                > .section-4-symbol1 {
                    right: 8vw;
                    bottom: -4rem;
                }
                > .section-4-symbol2 {
                    width: 14vw;
                    right: -4vw;
                    bottom: -4rem;
                }

                > #left {
                    display: flex;
                    flex-direction: column;
                    padding: clamp(2rem, 3.4vw, 4rem);
                    width: 100%;

                    > p {
                        font-size: clamp(0.6rem, 1.2vw, 1rem);
                        text-align: justify;
                    }

                    > .quote-name {
                        margin: 0.5rem 0;
                        font-size: clamp(0.6rem, 1.8vw, 1.5rem);
                    }
                }
                > #right {
                    display: flex;
                    flex-direction: column;
                    padding: 2rem;
                    background-color: $accent-color-1;
                    color: black;
                    text-align: right;
                }
                > .section-4-right {
                    width: 54vw;
                    height: 100%;
                    
                }
            }
            > .pinkbar {
                height: 3.4vw;
                border: 2px solid black;
                background-color: #FF4081;

            }
        }
        
        > .learn-more-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 4rem 0;

            > .link {
                text-decoration: none;
                > button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    background-color: $accent-color-1;
                    padding: 0.75rem 1.5rem;
                    margin-top: 2rem;
                    transition: 0.25s;
                    cursor: pointer;
                    border: none;

                    p {
                        transform: translateY(0.1em);
                        font-size: 2rem;
                        font-family: $title-font;

                    }
                    img {
                        width: 2rem;
                        margin-left: 1rem;
                    }
                }
                > button:hover {
                    text-decoration: none;
                    transform: scale(0.9);
                }
                > button:active {
                    background-color: #008437;
                }
            }
        }
    }
    @media only screen and (min-width: $mobile) {
        .mobile-cont {
            display: none;
        }
        .desktop-experience {
            display: inherit;
        }
    }

}