@import '../../styles/base.scss';



.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 84vw;
  height: 36vw;

  h1{
    font-size: clamp(0.6rem, 140px, 2rem); 
    font-family: $heading-font;
    font-weight: 100;
  }
  

  .img {
    position: relative;
    bottom: 24vw;
    left: calc(-360px + 100vw);
    height: 260px;
    width: auto;
  
  }
}


.threebox{
  width: 84vw;
  margin-top: -3vw;
  .shapesmobile.one {
    margin-bottom: 2.8vw;
  }
  .shapesmobile.two {
    margin-bottom: 1vw;
  }
  .shapesmobile.three {
    margin-bottom: 2.8vw;
  }


  .shapes {
    width: clamp(3rem, 20vw, 5rem);
    display: none;
    
  }
  .shapes.one{
    margin-bottom: 4vw;
  }

  .shapes.two{
    margin-bottom: 1vw;
  }
  .shapes.three{
    margin-bottom: 4vw;
  }

  h4{
    font-family: $heading-font;
    font-weight: 300;
    margin-bottom: 2vw;
    font-size: 1.125rem;
  }

  p {
    font-family: $p-font;
  }

  .box {
    margin-bottom: 4vw;
  }

  
}
.buttoncont {
  width: 84vw;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 6vw;
  margin-bottom: 10vw;
  .applybutton {
    background-color: $accent-color-1;
    width: 112px;
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 14px 8px 14px;
    text-decoration: none;

    align-items: center;

    &:hover,&:focus-visible {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
    &:active {
      background-color: #008437;
    }
    .text {
      color: $background-color;
      font-family: $button-font;
      font-size: 22px;
      line-height: 1em;
      height: 0.8em;
    }
  }
  .buttonstar {
    height: 24px;
    width: auto;
  }
}
  

@media (min-width: 601px) {

  .title {
    h1 {
      font-family: $heading-font;
      font-weight: 100;
      font-size: clamp(2rem, 5.2vw, 10rem);
      height: 8vw;
    }
    height: 12vw;
    margin-top: 3.4vw;
    width: 84vw;

    .img {
      left: calc(-500px + 80vw);
    }
  }
  .threebox {
    width: 84vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0vw;
  }
  .box {
    width: 26.2vw;
    height: auto;
    margin-bottom: 1vw;

    p {
      text-align: justify;
    }
    
    h4 {
      margin-bottom: 1vw;
    }
    .shapesmobile {
      display: none;
    }

    .shapes {
      display: block;
    }
    .shapes.one {
      height: 2.8vw;
      width: auto;
      margin-top: 1.2vw;
      margin-bottom: 1.4vw;
    }

    .shapes.two {
      height: 4.6vw;
      width: auto;
      margin-top: 0.54vw;
      margin-bottom: 0.22vw;
    }

    .shapes.three {
      height: 3.6vw;
      width: auto;
      margin-top: 0.8vw;
      margin-bottom: 0.8vw;
    }
  }

  .img {
    display: none;
  } 
  .buttoncont2 {
    height: 6vw;
    width: 14vw;
    display: flex;
    
  }
  .buttoncont {
    width: 84vw;
    height: 6vw;
    margin-top: 0vw;
    margin-bottom: 4.2vw;
    .applybutton {
      height: 3.8vw;
      width: 14vw;
      text-decoration: none;
      
      .text {
        font-size: 2.4vw;
        line-height: 2.4vw;
      }
      .buttonstar {
        height: 2vw;
        width: auto;
        margin-left: 0.8vw;
      }
      
    }
    .applybutton {
      display: flex !important;
      flex-direction: row;
    }

    .applybutton:hover {
      text-decoration: none;
    }


    .shrink-on-hover {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform;
      transition-property: transform;
    }


  }

}
