@import "../../styles/base.scss";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.landing {
  overflow-x: hidden;
}

.grid-container {
  display: grid !important;
  grid-template-columns: 5fr 7fr;
  grid-gap: 2px;
  width: 100vw !important;

  @media only screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }

  .grid-child-pink {
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: $mobile) {
      padding: 2.5rem 0;
    }

    .dco-logo {
      width: 15vw;

      @media only screen and (max-width: $mobile) {
        width: 36vw;
      }
    }

    .vda-logo {
      width: 32vw;
      margin-top: 2.5vw;

      @media only screen and (max-width: $mobile) {
        width: 64vw;
        margin-top: 1.5rem;
      }
    }
  }

  .grid-child-orange {
    background-color: $secondary-color;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 1.5vw;
    max-height: 25vw;

    .hero-title {
      font-family: $title-font;
      font-size: 6vw;
      line-height: 85%;

      @media only screen and (max-width: $mobile) {
        font-size: 10vw;
      }
    }
  }
}

.grid-container2 {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr !important;
  }

  .justify {
    text-align: justify;
  }

  .grid-child-img {

    @media only screen and (max-width: 800px) {
      display: none;
    }

  }

  .green-blob {
    width: 54vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    top: 24vw;
    max-height: 600px;
    object-fit: contain;
  }

  .grid-child-text {
    &-inner {
      padding-right: 20%;

    }
    padding: 4rem 8vw;

    @media only screen and (max-width: 1200px) {
      &-inner {
        padding-right: 0;
      }
    }

    @media only screen and (max-width: $mobile) {
      padding: 2.5rem 8vw;
    }

    h2 {
      margin-bottom: 2.5rem;

      @media only screen and (max-width: $mobile) {
        margin-bottom: 1.25rem;
      }
    }

    h3 {
      margin-bottom: 2.25rem;

      @media only screen and (max-width: $mobile) {
        font-size: 1rem;
      }
    }

    .bottom-container {
      display: flex;
      flex-direction: column;

      .response-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.5rem;

        img {
          height: 1.5rem;
          margin-right: 1.5rem;
        }
      }

      .response-container:last-child {
        margin-bottom: 0;
      }
    }
  }

  .grid-child-orange2 {
    background-color: $secondary-color;
    color: #000;
    display: flex;
    flex-direction: column;
    padding-left: 8vw;
    padding-right: 8vw;
    justify-content: center;

    @media only screen and (max-width: $mobile) {
      padding: 2.5rem 8vw;
    }

    .response-container {
      display: flex;
      flex-direction: row;
      margin-top: 1.5rem;

      img {
        height: 1.5rem;
        margin-right: 1.5rem;
      }

      p {
        font-size: 1.5rem;
        line-height: 100%;

        @media only screen and (max-width: $mobile) {
          font-size: 1.25rem;
        }
      }
    }

    .response-container:first-child {
      margin-top: 0;
    }
  }
}
