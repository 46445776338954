@import '../../styles/base.scss';

.showcase {
	&-section {
		margin-bottom: 0px;
	}
	padding: 2rem $desktop-padding;
	.skip-button {
		position: fixed;
		right: 13px;

		z-index: 99;

		background-color: $accent-color-1;
		border: none;
		height: 47px;
		width: 53px;

		cursor: pointer;

		#arrow-down {
			border: solid black;
			border-width: 0 7px 7px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
		}

		transition: bottom 0.4s cubic-bezier(0.17, 0.82, 0.64, 0.97);

		&-visible {
			// transform: translateY(0);
			bottom: 100px;
		}
		&-hidden {
			// transform: translateY(150px);
			bottom: -70px;
		}

		&:active {
			filter: brightness(85%);
		}
	}
	section {
		h1 {
			font-family: $title-font;
			font-size: 2.5rem;
			text-transform: uppercase;
			line-height: 100%;
			padding-bottom: 40px;
		}
		h2 {
			font-family: $heading-font;
			font-size: 2.5rem;
			text-transform: uppercase;
			line-height: 100%;
			padding-bottom: 40px;
		}
		p {
			font-family: $p-font;
			line-height: 160%;
			padding-bottom: 40px;
		}
		.dcomp-thumbnail-list-thumbnail {
			padding-top: 0;
			padding-bottom: 0;
			cursor: pointer;

			.dcomp-thumbnail-list-image {
				transition: transform 0.3s ease-in-out;
				&:hover {
					transform: scale(0.95);
				}
				&:not(:hover) {
					transform: scale(1);
				}
			}

			.dcomp-thumbnail-list-image-caption {
				display: block;
				text-align: left;
				font-family: $heading-font;
				padding: 10px 0 40px;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	
}

.view-showcase-button {
	margin-top: 40px;
	padding: 1rem 1rem;
	text-decoration: none;
	background-color: $accent-color-1;
	color: black;
	outline: none;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.25s;

	img {
	  padding-left: 1em;
  	}

	span {
	  font-family: $title-font;
	  transform: translateY(0.1em);
	  -webkit-transform: translateY(0.1em);
	  text-transform: uppercase;
	  font-size:  clamp(.8rem,calc(1vw + 1rem),2rem);
	}

	&:hover, &:focus-visible {
	  transform: scale(0.9);
	  cursor: pointer;
	}

	&:active {
		filter: brightness(75%);
  	}

	@media only screen and (max-width: $mobile) {
        width: 100%;
		span {
			font-size: 2rem;
		}
    }

	@media only screen and (max-width: 460px) {
		span {
			font-size: calc(6.5vw);
		}
    }

	@media only screen and (max-width: 360px) {
		span {
			font-size: calc(3vw + 8px);
		}
    }
  }
