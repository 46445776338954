@import '../../styles/base.scss';

.tools {
    @media only screen and (max-width: $mobile) {
        padding-top: 20vw;
        position: relative;
    }
    .grid-container2 {
        p {
            text-align: start;
        }
        .pink-blob {
            position: absolute;
            width: 50vw;
            transform: translateY(-20vw);

            @media only screen and (max-width: $mobile) {
                transform: translateY(-32vw);
            }
        }
        @media only screen and (max-width: $mobile) {
            .grid-child-img {
                display: flex;
                .pink-blob {
                    width: 55vw;
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-top: 4vw;
                }
            }
        }
        
        
    }
}